<template>
  <b-modal
    id="modal-host"
    ref="refModal"
    title="Chủ nhà"
    cancel-title="Huỷ"
    cancel-variant="outline-secondary"
    size="lg"
    :ok-title="isSubmitting ? 'Đang lưu' : 'Lưu'"
    :ok-disabled="isSubmitting"
    :hide-header-close="true"
    no-close-on-backdrop
    @show="resetModal"
    @hidden="resetModal"
    @ok="onSubmit"
  >
    <!-- Body -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <b-col md="4">
            <b-form-group>
              <template v-slot:label>
                Tên chủ nhà <span class="text-danger" />
              </template>
              <b-form-input
                id="name"
                v-model="itemLocal.name"
                disabled
                placeholder=""
                trim
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <template v-slot:label>
                Số điện thoại <span class="text-danger" />
              </template>
              <b-form-input
                id="phone"
                v-model="itemLocal.phone"
                disabled
                placeholder=""
                trim
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <template v-slot:label>
                Email <span class="text-danger" />
              </template>
              <b-form-input
                id="email"
                v-model="itemLocal.email"
                disabled
                placeholder=""
                trim
              />
            </b-form-group>
          </b-col>

          <!-- submit -->
        </b-row>
        <b-row>
          <b-col
            cols="12"
            class="my-1"
          >
            <div class="d-flex justify-space-between">
              <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary">
                1. Hạn mức sử dụng
              </div>
            </div>
          </b-col>
          <b-col md="4">
            <validation-provider
              #default="validationContext"
              name="Số phòng"
              rules="required"
            >
              <number-input
                v-model="itemLocal.maxRoomSlots"
                required
                label="Số phòng"
                :default-value="itemLocal.maxRoomSlots"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>
          <b-col md="4">
            <validation-provider
              #default="validationContext"
              name="Số giường"
              rules="required"
            >
              <number-input
                v-model="itemLocal.maxBedSlots"
                required
                label="Số giường"
                :default-value="itemLocal.maxBedSlots"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>
          <b-col md="4">
            <b-form-group label="Số đơn vị thuê tối đa">
              <b-form-input
                v-model="maxSlots"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <validation-provider
              #default="validationContext"
              name="Số tin nhắn Zalo OA"
              rules="required"
            >
              <number-input
                v-model="itemLocal.zaloZnsCount"
                required
                label="Số tin nhắn Zalo OA"
                :default-value="itemLocal.zaloZnsCount"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>
          <b-col md="4">
            <validation-provider
              #default="validationContext"
              name="Số giao dịch Tingee"
              rules="required"
            >
              <number-input
                v-model="itemLocal.tingeeTransactionCount"
                required
                label="Số giao dịch Tingee"
                :default-value="itemLocal.tingeeTransactionCount"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>
          <b-col md="4">
            <validation-provider
              #default="validationContext"
              name="endDate"
              rules="required"
            >
              <select-date
                v-model="itemLocal.expiredIn"
                required
                :default-value="itemLocal.expiredIn"
                label="Hạn sử dụng"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>
        </b-row>

      </b-form>
    </validation-observer>

  </b-modal>
</template>

<script>
import {
  BModal,
  BFormGroup,
  BFormInput,
  BForm,
  BRow,
  BCol,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import NumberInput from '@/views/components/NumberInput.vue';
import SelectDate from '@/views/components/SelectDate.vue';
import useHostModal from './useHostModal';

export default {
  components: {
    BModal,
    BFormGroup,
    BFormInput,
    BForm,
    BCol,
    BRow,
    ValidationProvider,
    ValidationObserver,

    NumberInput,
    SelectDate,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
    };
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const {
      refModal,
      itemLocal,
      maxSlots,
      resetItemLocal,
      resetModal,
      isSubmitting,
      onSubmit,
    } = useHostModal(props, emit, refFormObserver);

    return {
      refModal,
      itemLocal,
      isSubmitting,
      maxSlots,
      resetItemLocal,
      resetModal,
      onSubmit,

      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
    };
  },
};
</script>
